html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

::-webkit-input-placeholder {
  color: inherit; }

::-moz-placeholder {
  color: inherit;
  opacity: 1; }

input[type=tel], input[type=url], input[type=password],
input[type=text], input[type=email], input[type=reset],
input[type=button], input[type=submit], button, textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

img {
  vertical-align: middle; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a[href], label[for], select,
input[type=checkbox], input[type=radio] {
  cursor: pointer; }

button, input[type=button], input[type=image],
input[type=reset], input[type=submit] {
  padding: 0;
  overflow: visible;
  cursor: pointer; }

button::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=image]::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner {
  border: 0; }

.hide {
  position: absolute !important;
  left: -9999em !important; }

.clearfix:after {
  content: "";
  display: block;
  clear: both; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

strong {
  font-weight: bold; }

em {
  font-style: italic; }

del {
  text-decoration: line-through; }

th, td {
  vertical-align: top; }

th {
  font-weight: normal;
  text-align: left; }

address, cite, dfn {
  font-style: normal; }

abbr, acronym {
  border-bottom: 1px dotted #999;
  cursor: help; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

textarea {
  overflow: auto; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
.slick-list:focus {
  outline: none; }
.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
.slick-track:before, .slick-track:after {
  content: "";
  display: table; }
.slick-track:after {
  clear: both; }
.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  display: none; }
[dir="rtl"] .slick-slide {
  float: right; }
.slick-slide img {
  display: block; }
.slick-slide.slick-loading img {
  display: none; }
.slick-slide.dragging img {
  pointer-events: none; }
.slick-initialized .slick-slide {
  display: block; }
.slick-loading .slick-slide {
  visibility: hidden; }
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

img {
  max-width: 100%;
  height: auto; }

/* Normalized Styles
------------------------------------------------------------------------------*/
body {
  font: 300 14px/24px 'Montserrat', sans-serif;
  color: #616e7e;
  background: #e3ecf3 url(../images/bg_top.png) no-repeat 50% 0;
  -webkit-text-size-adjust: none; }

input, textarea, select, button {
  outline: none;
  font-family: 'Montserrat', sans-serif; }

a {
  text-decoration: none;
  color: #236093; }

a,
button,
.btn,
input {
  transition: all .2s ease;
  outline: none; }

/* Container
------------------------------------------------------------------------------*/
.container {
  position: relative;
  width: 1320px;
  padding: 0 20px;
  max-width: 100%;
  margin: 0 auto; }

/* Header
------------------------------------------------------------------------------*/
.header {
  width: 1280px;
  margin: 17px auto;
  height: 108px;
  background-color: #eef3f7;
  border-radius: 54px;
  box-shadow: 0 30px 50px rgba(121, 159, 170, 0.2);
  padding: 26px 39px; }
.header .container {
  padding: 0; }

.menu {
  color: #263441;
  font-size: 16px;
  font-weight: 400;
  padding: 18px 7px;
  line-height: 20px; }
.menu .email {
  background: url(../images/ico_email.png) no-repeat 0 50%;
  padding-left: 27px;
  margin-left: 54px; }
.menu .phone {
  background: url(../images/ico_phone.png) no-repeat 0 50%;
  padding-left: 22px; }
.menu a {
  color: inherit; }
.menu a:hover {
  opacity: .8; }
.menu ul {
  float: left;
  padding-left: 56px; }
.menu li {
  padding-right: 85px;
  float: left;
}
.menu .contact {
  float: right; }
.menu .contact p {
  float: left; }

.sub-menu {
  text-align: center;
  padding: 21px 0 11px; }
.sub-menu ul {
  border-bottom: 1px solid #e4e9ed;
  padding-left: 25px;
  position: relative; }
.sub-menu li {
  display: inline-block;
  text-align: left;
  line-height: 20px;
  padding: 0 24px 10px; }
.sub-menu .container > ul > li:hover > a {
  color: #373d43; }
.sub-menu .container > ul > li > a {
  color: #616e7e;
  font-size: 14px;
  font-weight: 300;
  display: block;
  padding-bottom: 31px;
  position: relative;
  padding-right: 18px; }
.sub-menu .container > ul > li > a:hover {
  color: #373d43; }
.sub-menu .container > ul > li > a:after {
  content: "";
  position: absolute;
  top: 7px;
  right: -2px;
  background: url(../images/ico_down.png) no-repeat;
  width: 11px;
  height: 8px; }
.sub-menu li:hover ul {
  pointer-events: auto;
  opacity: 1; }
.sub-menu ul ul {
  position: absolute;
  top: 100%;
  pointer-events: none;
  opacity: 0;
  transition: all .2s ease;
  left: 0;
  border-bottom: 0;
  padding: 25px 15px 15px;
  text-align: center;
  z-index: 99;
  background: #fff;
  right: 0; }
.sub-menu ul ul a {
  color: #353536;
  margin-right: 14px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px; }
.sub-menu ul ul a:hover {
  color: #616e7e; }

.logo {
  background: url(../images/logo.svg) no-repeat;
  background-size: contain;
  width: 164px;
  height: 51px;
  text-indent: -999em;
  float: left; }

/* Content
------------------------------------------------------------------------------*/
.body {
  border-top: 1px solid #d2e0ea;
  padding: 63px 0 150px;
  border-bottom: 1px solid #d2e0ea; }

.image-r {
  float: right;
  margin-right: -25px;
  max-width: 40%;
  margin-left: 25px; }

form {
  margin-left: -68px;
  overflow: hidden; }
form input[type=tel], form input[type=url], form input[type=password], form input[type=text], form input[type=email] {
  width: 100%;
  height: 50px;
  border: 1px solid #d2e0ea;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  color: #a2a2a2;
  font-size: 15px;
  padding: 0 13px;
  font-weight: 300;
  margin-bottom: 16px;
  line-height: 28px; }
form .rezervation-form > div {
  float: left;
  width: 29.8%;
  padding-left: 68px; }
form .rezervation-form > div:nth-child(2) {
  width: 30%; }
form .rezervation-form > div:nth-child(3) {
  width: 40%; }
form .rezervation-form > div:nth-child(4) {
  width: 100%; }
form textarea {
  width: 100%;
  height: 165px;
  border: 1px solid #d2e0ea;
  border-radius: 5px;
  color: #a2a2a2;
  font-size: 15px;
  padding: 10px 13px;
  font-weight: 300;
  display: block;
  margin-bottom: 40px;
  line-height: 28px; }
form label {
  display: block;
  color: #263441;
  font-size: 14px;
  font-weight: 300;
  line-height: 29px; }
form .cms_submit {
  width: 296px;
  max-width: 100%;
  display: block;
  color: #263441;
  font-size: 14px;
  font-weight: 300;
  clear: both;
  line-height: 50px;
  border: 0;
  height: 50px;
  background: #d2e0ea;
  border-radius: 5px; }
form .cms_submit:hover {
  background: #e3ecf3; }

.map-r {
  float: right;
  width: 70.2%;
  margin: 0 -25px 0 20px;
  height: 505px; }

.contact + .entry.form {
  border-top: 0; }

.entry {
  padding: 47px 68px 49px;
  margin: 0 10px;
  background-color: white;
  border-radius: 10px 10px 0 0; }
.entry:last-child {
  border-radius: 0 0 10px 10px; }
.entry h2 {
  padding-bottom: 18px;
  color: #263441;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px; }
.entry p {
  padding-bottom: 23px; }
.entry p a {
  color: #005683;
  font-weight: 300;
  word-wrap: break-word;
  text-decoration: underline; }
.entry p a:hover {
  text-decoration: none; }
.entry.desc {
  padding-bottom: 40px;
  border-radius: 10px 10px 0 0; }
.entry.desc:after {
  content: "";
  display: block;
  clear: both; }
.entry.desc h2 {
  padding-bottom: 10px; }
.entry.desc p {
  line-height: 36px;
  padding-bottom: 39px; }
.entry.contact {
  padding-bottom: 40px;
  border-radius: 10px 10px 0 0; }
.entry.contact:after {
  content: "";
  display: block;
  clear: both; }
.entry.contact h2 {
  padding-bottom: 10px; }
.entry.contact p + h2 {
  padding-top: 75px; }
.entry.contact p {
  line-height: 36px;
  padding-bottom: 29px; }
.entry.form {
  border-top: 1px solid #d2e0ea;
  padding-bottom: 75px;
  border-radius: 0 0 10px 10px;
  padding-right: 42px; }
.entry.form h2 {
  padding-bottom: 39px; }
.entry.offer {
  text-align: center; }
.entry.offer p {
  font-size: 14px;
  line-height: 20px;
  color: #616e7e;
  padding-bottom: 40px;
  font-weight: 700; }
.entry.offer p + p {
  padding-bottom: 30px;
  padding-top: 20px; }
.entry.offer p strong {
  color: #d61313; }
.entry.offer p a {
  color: #616e7e;
  font-weight: 300;
  text-decoration: underline;
  padding-bottom: 23px; }
.entry.offer img {
  margin-bottom: 50px; }
.entry.offer h2 {
  padding-bottom: 60px;
  color: #263441;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; }

.body .cols {
  display: flex;
  padding-bottom: 45px; }
.body .cols .col {
  width: 50%;
  padding-right: 70px;
  padding-left: 55px; }
.body .cols .col:last-child {
  padding-right: 15px; }
.body .cols .col:first-child {
  padding-left: 0; }

.title {
  padding: 33px 0 60px; }
.title h2 {
  color: #263441;
  font-size: 36px;
  text-align: center;
  padding: 42px 0 21px;
  font-weight: 700;
  line-height: 20px; }
.title h2 + p:before {
  display: none; }
.title p {
  width: 640px;
  text-align: center;
  max-width: 100%;
  margin: auto;
  color: #6c7781;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px; }
.title p:before {
  margin: 16px auto 19px;
  content: "";
  display: block;
  width: 35px;
  height: 2px;
  background-color: #5c9c2d; }

.products {
  padding: 5px 0 0;
  margin-bottom: -17px; }
.products article {
  margin: 0 10px 15px;
  padding: 20px 20px 25px;
  background-color: white;
  border-radius: 10px; }
.products article .image {
  width: 134px;
  text-align: center; }
.products article h3 {
  color: #263441;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 16px;
  line-height: 20px; }
.products article p {
  color: #616e7e;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px; }
.products article .text {
  width: 80%;
  padding-left: 25px; }
.products article .more {
  background: url(../images/ico_dots.png) no-repeat 50% 0;
  padding-top: 10px;
  color: #939598;
  font-size: 12px;
  width: 13%;
  text-align: center;
  font-weight: 300;
  line-height: 20px; }
.products article a {
  min-height: 127px;
  display: flex;
  align-items: center; }

h1 {
  text-align: center;
  color: #263441;
  font-size: 36px;
  font-weight: 800;
  line-height: 1.5; }

.home .sub-menu .container > ul > li > a {
  padding-bottom: 23px; }

.text-entry {
  padding: 38px 0 0 10px; }
.text-entry .ico {
  padding-bottom: 15px; }
.text-entry p {
  font-size: 16px;
  padding-bottom: 32px;
  line-height: 32px; }
.text-entry h2 {
  color: #263441;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  padding: 10px 0 5px; }
.text-entry h2:after {
  content: "";
  margin: 24px 0 15px;
  width: 35px;
  height: 2px;
  background-color: #5c9c2d;
  display: block; }

.mode-row {
  padding-bottom: 50px;
  border-bottom: 1px solid #d2e0ea;
  padding-top: 50px; }
.mode-row section {
  padding: 62px 0 0;
  display: flex;
  justify-content: space-around; }
.mode-row article {
  width: 25%; }
.mode-row a {
  display: block;
  text-align: center;
  width: 277px;
  max-width: 100%;
  height: 283px;
  margin: auto;
  display: flex;
  padding-top: 20px;
  align-content: center;
  flex-wrap: wrap;
  position: relative; }
.mode-row a:before {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 10px;
  left: 0;
  transition: all .2s ease;
  right: 0;
  content: "";
  background: white url(../images/bg_logo.png) no-repeat 50% 100%;
  box-shadow: 0 30px 50px rgba(121, 159, 170, 0.2); }
.mode-row a:hover:before {
  opacity: 1; }
.mode-row a:hover .btn {
  opacity: 1; }
.mode-row a:hover h4 {
  font-weight: 600; }
.mode-row a:hover p {
  color: #6fa748;
  font-weight: 600; }
.mode-row .logo-h {
  width: 100%; }
.mode-row h4 {
  color: #6c7781;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 300;
  width: 100%;
  padding: 19px 0 0;
  line-height: 29px; }
.mode-row section p {
  color: #6c7781;
  font-size: 14px;
  width: 100%;
  font-weight: 300;
  line-height: 24px; }
.mode-row .btn {
  opacity: 0;
  transition: all .2s ease;
  width: 158px;
  margin: auto;
  display: block;
  margin-top: 35px;
  height: 37px;
  background-color: white;
  color: #263441;
  font-size: 13px;
  font-weight: 300;
  line-height: 37px;
  border-radius: 19px;
  box-shadow: 0 15px 16px rgba(58, 94, 104, 0.2); }

.about-row {
  position: relative;
  padding: 25px 0 34px;
  margin-bottom: -45px; }
.about-row .text-entry {
  padding-right: 5%;
  padding-left: 48%; }
.about-row:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  background: url(../images/about.png) no-repeat 0 100%;
  left: 0;
  right: 52%;
  background-size: contain; }

.serices-row {
  overflow: hidden;
  margin: 20px 0 0;
  padding-bottom: 122px;
  position: relative;
  z-index: 10; }
.serices-row .back-text {
  z-index: -1;
  position: absolute;
  top: 210px;
  left: 51%;
  text-align: center;
  color: #d9e7f0;
  pointer-events: none;
  font-size: 180px;
  font-weight: 700;
  line-height: 29px; }
.serices-row .container {
  align-items: flex-start;
  display: flex;
  justify-content: space-between; }
.serices-row .text-entry {
  width: 50%; }
.serices-row .main-r {
  width: 370px;
  margin: 0 10px; }

.main-r {
  overflow: hidden;
  background-color: white;
  padding-bottom: 50px;
  border-radius: 10px;
  box-shadow: 0 30px 50px rgba(121, 159, 170, 0.2); }
.main-r p {
  color: #263441;
  font-size: 16px;
  font-weight: 300;
  padding: 0 20px 42px 35px;
  letter-spacing: -.01em;
  line-height: 29px; }
.main-r ul {
  margin: 0 36px;
  overflow: hidden;
  border: 1px solid #d2e0ea;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap; }
.main-r ul li {
  height: 87px;
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: -1px;
  border-right: 1px solid #d2e0ea;
  border-bottom: 1px solid #d2e0ea; }
.main-r ul li:nth-child(even) {
  border-right: 0; }
.main-r ul span {
  display: block;
  color: #69a440;
  font-size: 15px;
  width: 100%;
  font-weight: 300;
  line-height: 28px; }
.main-r .image {
  position: relative;
  margin-bottom: 57px; }
.main-r .image .ico {
  position: absolute;
  top: 100%;
  margin: auto;
  margin-top: -30px;
  text-align: center;
  z-index: 9;
  left: 0;
  right: 0; }
.main-r .image > img {
  width: 100%; }

.main-intro {
  overflow: hidden;
  padding: 70px 0 511px;
  position: relative;
  z-index: 5;
  background: url(../images/bg_water.jpg) no-repeat 50% 100%; }
.main-intro .container {
  display: flex;
  align-items: center; }
.main-intro .back-text {
  position: absolute;
  top: 252px;
  bottom: 0;
  pointer-events: none;
  left: 0;
  right: 0;
  color: #dceaf3;
  z-index: -1;
  font-family: Montserrat;
  font-size: 320px;
  font-weight: 700;
  text-align: center;
  line-height: 20px; }
.main-intro h1 {
  color: #062230;
  font-size: 75px;
  font-weight: 700;
  padding-bottom: 40px;
  line-height: 70px; }
.main-intro h1 span {
  color: #062230;
  font-size: 100px;
  font-weight: 700; }
.main-intro .featured {
  width: 28%;
  padding-bottom: 8px; }
.main-intro h4 {
  color: #062230;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  padding-bottom: 20px; }
.main-intro li {
  position: relative;
  padding-left: 35px;
  padding-bottom: 25px;
  color: #676869;
  font-size: 14px;
  max-width: 190px;
  font-weight: 300;
  line-height: 20px; }
.main-intro li .ico {
  position: absolute;
  left: -7px;
  top: 4px;
  width: 35px;
  text-align: center; }
.main-intro .text {
  width: 42%; }
.main-intro .image {
  width: 50%; }

.home {
  background-image: linear-gradient(to top, white 0%, #e3ecf3 50%); }

/* Footer
------------------------------------------------------------------------------*/
.footer {
  padding: 147px 0 254px;
  color: #6c7781;
  background: url(../images/bg_footer_i.jpg) no-repeat 50% 100%, linear-gradient(to top, #eef3f8 0%, #e3ecf3 100%);
  font-size: 14px;
  font-weight: 300;
  line-height: 19px; }
.home .footer {
  padding-bottom: 75px;
  background: url(../images/bg_footer.jpg) no-repeat 50% 100%, linear-gradient(to top, #e3ecf3 0%, #f7f9fb 100%); }
.footer .cols {
  display: flex; }
.footer .left {
  width: 25%;
  padding-left: 10px; }
.footer .col {
  width: 25%; }
.footer .col:nth-child(3) {
  width: 25%; }
.footer .col:last-child {
  width: 25%; }
.footer .col:last-child p {
  padding-bottom: 17px; }
.footer .col:last-child p a {
  margin-left: 5px;
  text-decoration: underline; }
.footer .col:last-child p.phone {
  font-size: 17px; }
.footer .col:last-child p.phone a {
  color: inherit;
  display: inline-block;
  text-decoration: none;
  margin-left: 0;
  margin-right: 12px; }
.footer img {
  margin-bottom: 15px; }
.footer li {
  padding-bottom: 19px; }
.footer li a {
  color: inherit; }
.footer p a {
  color: #5c9c2d; }
.footer a:hover {
  opacity: .8; }
.footer p {
  padding-bottom: 37px; }
.footer p.by {
  padding-top: 25px; }
.footer h6 {
  color: #263441;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 46px;
  line-height: 20px; }

/* Fonts
------------------------------------------------------------------------------*/
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/MontserratBold.eot");
  src: url("../fonts/MontserratBold.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratBold.woff") format("woff"), url("../fonts/MontserratBold.ttf") format("truetype"), url("../fonts/MontserratBold.svg#FiltraiMontserratBold") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/MontserratExtraBold.eot");
  src: url("../fonts/MontserratExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratExtraBold.woff") format("woff"), url("../fonts/MontserratExtraBold.ttf") format("truetype"), url("../fonts/MontserratExtraBold.svg#MontserratExtraBold") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/MontserratLight.eot");
  src: url("../fonts/MontserratLight.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratLight.woff") format("woff"), url("../fonts/MontserratLight.ttf") format("truetype"), url("../fonts/MontserratLight.svg#MontserratLight") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/MontserratRegular.eot");
  src: url("../fonts/MontserratRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratRegular.woff") format("woff"), url("../fonts/MontserratRegular.ttf") format("truetype"), url("../fonts/MontserratRegular.svg#MontserratRegular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/MontserratSemiBold.eot");
  src: url("../fonts/MontserratSemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratSemiBold.woff") format("woff"), url("../fonts/MontserratSemiBold.ttf") format("truetype"), url("../fonts/MontserratSemiBold.svg#MontserratSemiBold") format("svg");
  font-weight: 600;
  font-style: normal; }

/* Media
------------------------------------------------------------------------------*/
@media screen and (max-width: 1320px) {
  .body {
    padding: 80px 0; }
  .sub-menu ul ul {
    text-align: center;
    padding: 15px;
    padding-bottom: 0; }
  .sub-menu li ul {
    text-align: center; }
  .sub-menu li li {
    float: none;
    display: inline-block;
    padding: 0 5px 15px; }
  .footer {
    padding-top: 80px; }
  .header {
    width: auto;
    margin-left: 20px;
    margin-right: 20px; }
  .entry {
    padding: 40px;
    padding-bottom: 20px; }
  .body .cols .col {
    padding-right: 20px; }
  .menu ul {
    padding-left: 30px; }
  .menu li {
    padding-right: 30px !important; }
  .menu .email {
    margin-left: 30px; }
  .footer .col:last-child {
    width: 20%; }
  .footer .left {
    width: 30%; }
  .about-row {
    padding-top: 0; }
  .main-intro .image {
    width: 40%;
    padding-right: 20px; }
  .main-intro .back-text {
    top: 190px; } }

@media screen and (max-width: 1023px) {
  .map-r {
    float: none;
    width: auto;
    margin: 0 0 40px;
    height: 250px; }
  .entry.contact {
    padding-bottom: 0; }
  .entry.contact p + h2 {
    padding-top: 0; }
  form .rezervation-form > div {
    padding-left: 20px; }
  form {
    margin-left: -20px; }
  .about-row {
    margin-bottom: 0; }
  .products article {
    margin-left: 0;
    margin-right: 0; }
  .body {
    padding: 40px 0; }
  .footer {
    padding-top: 40px; }
  .footer .cols {
    flex-wrap: wrap; }
  .footer .left {
    width: 50%;
    padding-left: 0; }
  .footer .left .by {
    padding-top: 0; }
  .footer .col {
    width: 50% !important;
    padding-bottom: 20px; }
  .menu {
    overflow: hidden;
    padding: 0; }
  .menu ul {
    float: right;
    padding: 0; }
  .menu ul li {
    padding: 0 0 15px 50px !important; }
  .menu .contacts {
    float: none;
    clear: right;
    padding: 0; }
  .entry {
    margin: 0; }
  .sub-menu ul {
    margin: 0 -10px;
    padding-left: 0; }
  .main-intro {
    padding-top: 30px; }
  .sub-menu li {
    padding-left: 10px;
    padding-right: 10px; }
  .main-intro .back-text {
    top: 100px;
    font-size: 24vw; }
  .about-row .text-entry {
    padding: 0 0 150px; }
  .mode-row section {
    flex-wrap: wrap; }
  .mode-row section article {
    width: 50%; }
  .main-intro h1 {
    font-size: 50px;
    line-height: 55px; }
  .main-intro h1 span {
    font-size: 66px; }
  .serices-row .text-entry {
    padding: 0; }
  .serices-row .back-text {
    left: 45%;
    font-size: 15vw; }
  .serices-row .main-r {
    margin-right: 0;
    width: 350px; } }

@media screen and (min-width: 768px) {
  .mobile-only {
    display: none !important; } }

@media screen and (max-width: 767px) {
  form .rezervation-form > div:nth-child(3),
  form .rezervation-form > div,
  form .rezervation-form > div:nth-child(2) {
    width: 100%; }
  form .cms_submit {
    margin-left: 0;
    width: auto;
    padding: 0 40px; }
  .map-r {
    margin: -20px;
    margin-bottom: 20px; }
  .image-r {
    margin: -20px -20px 20px;
    float: none;
    max-width: none; }
  .image-r img {
    width: 100%; }
  .entry.desc {
    padding-bottom: 0; }
  .entry.form {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 20px; }
  .entry.form h2 {
    padding-bottom: 18px; }
  .products article {
    padding: 15px;
    margin: 0 -20px 15px;
    text-align: center;
    border-radius: 0; }
  .products article a {
    display: block; }
  .products article .more {
    margin: 0;
    width: auto; }
  .products article .image {
    text-align: center;
    width: auto;
    padding: 0 0 15px; }
  .products article .text {
    width: auto;
    padding: 0 0 20px; }
  .products article .text br {
    display: none; }
  .entry.offer h2 {
    padding-bottom: 20px; }
  .entry.offer img {
    margin-bottom: 20px; }
  .sub-menu {
    display: none; }
  .header {
    margin: 20px 20px;
    padding: 27px 20px;
    border-radius: 30px;
    transition: all .2s ease; }
  .title {
    padding: 25px 0; }
  .entry {
    padding: 20px;
    padding-bottom: 0; }
  .body .cols {
    display: block;
    padding: 0;
    margin: 0; }
  .body .cols .col {
    width: auto;
    float: none;
    padding: 0; }
  .entry.offer p + p {
    padding-top: 0; }
  .footer p {
    padding-bottom: 20px; }
  .footer .col:last-child p.phone {
    line-height: 30px; }
  .body .entry {
    margin: 0 -20px;
    border-radius: 0; }
  .footer {
    padding: 30px 0;
    background: none;
    font-size: 13px;
    line-height: 15px; }
  .footer .left .by {
    position: absolute;
    bottom: 0;
    margin: -50px 20px;
    left: 0;
    right: 0; }
  .footer .left,
  .footer .col {
    width: 100% !important;
    padding: 0 0 15px; }
  .footer h6 {
    padding-bottom: 15px; }
  .active-menu .header {
    border-radius: 30px 30px 0 0; }
  .text-entry p,
  .serices-row .main-r p,
  .title p {
    font-size: 14px;
    line-height: 1.8; }
  .serices-row .main-r p {
    text-align: center;
    padding: 15px; }
  .mode-row a:before {
    display: none; }
  .mode-row a {
    height: auto;
    padding: 20px 0; }
  .mode-row .btn {
    margin-top: 20px;
    opacity: 1; }
  .wrap {
    position: absolute;
    top: 75px;
    z-index: 99;
    background: #eef3f7;
    margin: -20px;
    border-radius: 0 0 20px 20px;
    left: 0;
    box-shadow: 0 30px 50px rgba(121, 159, 170, 0.4);
    right: 0;
    padding: 20px;
    opacity: 0;
    overflow: auto;
    max-height: calc(100vh - 140px);
    pointer-events: none; }
  .active-menu .wrap {
    pointer-events: auto;
    opacity: 1; }
  .menu ul.mobile-only {
    border-top: 1px solid #d2e0ea;
    border-bottom: 1px solid #d2e0ea;
    margin: 0 -20px 20px;
    padding: 15px 20px 5px; }
  .menu ul.mobile-only ul > li > a {
    font-size: 15px; }
  .menu ul.mobile-only > li > a {
    font-size: 15px; }
  .menu ul.mobile-only > li > a:after {
    content: "";
    display: inline-block;
    background: url(../images/ico_down.png) no-repeat;
    width: 11px;
    height: 8px;
    margin-left: 8px; }
  h1 {
    font-size: 26px;
    line-height: 1.5; }
  .menu .contact {
    float: none; }
  .menu .contact p {
    float: none;
    margin-left: 2px;
    margin-top: 10px;
    margin-bottom: 5px; }
  .menu ul {
    float: none;
    padding: 0; }
  .menu ul br {
    display: none; }
  .menu ul ul {
    display: none;
    padding-left: 15px;
    padding-top: 15px; }
  .menu ul ul.active {
    display: block; }
  .menu ul li {
    padding: 0 0 15px !important;
    float: none; }
  .header .trigger {
    position: absolute;
    top: 15px;
    z-index: 9;
    display: block;
    cursor: pointer;
    width: 30px;
    padding-top: 5px;
    height: 40px;
    right: 0; }
  .header .trigger span {
    height: 3px;
    transition: all .2s ease;
    background: #00529b;
    display: block;
    margin: 0 0 5px; }
  .active-menu .header .trigger span:nth-child(2) {
    opacity: 0; }
  .active-menu .header .trigger span:nth-child(3) {
    transform: rotate(-45deg); }
  .active-menu .header .trigger span:nth-child(1) {
    transform: rotate(45deg);
    margin: 9px 0 -12px; }
  .serices-row .container {
    display: block; }
  .main-intro .text {
    width: auto; }
  .serices-row .text-entry {
    width: auto; }
  .serices-row {
    padding-bottom: 45px; }
  .mode-row section {
    padding: 0; }
  .mode-row section article {
    width: 100%;
    margin: 0 0 20px; }
  .about-row .text-entry {
    padding-bottom: 15vw; }
  .mode-row {
    padding: 0; }
  .title h2,
  .text-entry h2 {
    font-size: 24px;
    line-height: 1.5; }
  .serices-row .main-r {
    width: auto;
    margin: 0;
    padding-bottom: 20px; }
  .serices-row .main-r p {
    padding: 0 20px 15px; }
  .serices-row .main-r ul {
    margin: 10px 20px; }
  .main-intro {
    padding-bottom: 200px;
    padding-top: 0;
    background-size: auto 240px; }
  .main-intro .featured {
    width: auto;
    padding: 0; }
  .main-intro .featured ul {
    display: inline-block; }
  .main-intro .featured ul li {
    text-align: left; }
  .main-intro .container {
    display: block;
    text-align: center; }
  .main-intro .image {
    width: auto;
    padding: 0 0 20px; }
  .main-intro .image img {
    max-width: 80%; }
  .main-intro h1 {
    width: auto;
    font-size: 15vw;
    padding-bottom: 15px;
    line-height: 1; }
  .main-intro h1 span {
    font-size: 20vw; } }



table {
  border-spacing: 0;
  border-collapse: collapse;
}
td,
th {
  padding: 0;
}
.table {
  border-collapse: collapse !important;
}
.table td,
.table th {
  background-color: #fff !important;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd !important;
}
table {
  background-color: transparent;
}
caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}
th {
  text-align: left;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #ddd;
}
.table .table {
  background-color: #fff;
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}
.table-bordered {
  border: 1px solid #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}
table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}
.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

.rezervation-form .has-error label{
  color: red;
}

.rezervation-form .has-error input{
  color: red;
  border-color: red;
  margin-bottom: 0;
}

.rezervation-form .has-error textarea{
  color: red;
  border-color: red;
  margin-bottom: 0;
}

.rezervation-form .has-error .error{
  color: red;
}

.image-r img {
  max-height: 600px;
}

.products article .image img {
  max-height: 180px;
}

.container-bottom {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 0 80px;
  border-bottom: 1px solid #d2e0ea ;  }

.container-bottom h2 {
  text-align: center;
  color: #263441 ;
  font-size: 28px;
  font-weight: 800;
  line-height: 1.5;
}

.container-bottom .title p {
  width: 640px;
  text-align: center;
  max-width: 100%;
  margin: auto;
  color: #6c7781 ;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
}